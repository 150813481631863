import React, { useEffect, useState } from 'react';
import { Units } from '../../conf/constants';
import CheckboxLabel from '../interface/global/CheckboxLabel';
import NumberSpan from '../interface/global/NumberSpan';
import StringItemList from '../interface/global/StringItemList';
import ToggleButton from '../interface/global/ToggleButton';
import YearMonthSpan from '../interface/global/YearMonthSpan';
import { HouseIcon } from '../misc/icons';
import Acreage from './Acreage';
import Brf from './Brf';
import HousingValue from './HousingValue';
import styles from './styles/CriteriaGroup.module.scss';

interface HousingProps {
  active: boolean;
  housingType: ('V' | 'B' | 'H' | 'X' | 'F' | 'K' | 'R' | 'G')[];
  roomCount: ('1' | '2' | '3' | '4' | '5' | '6-30')[];
  housingArea: string[];
  housingValue: string[];
  brfOrgNo: string[];
  acreage: string[];
  constructionYear: string[];
  updateSelectionCriteria: (path: string[], value: any) => void;
}

const HousingCriteriaGroup: React.FC<HousingProps> = ({
  active,
  housingType,
  housingArea,
  roomCount,
  housingValue,
  brfOrgNo,
  acreage,
  constructionYear,
  updateSelectionCriteria,
}) => {
  const setHousingType = (
    items: ('V' | 'B' | 'H' | 'X' | 'F' | 'K' | 'R' | 'G')[],
  ) => {
    updateSelectionCriteria(['criteria', 'housingType'], items);
  };
  const setHousingArea = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'housingArea'], items);
  };
  const setRoomCount = (items: ('1' | '2' | '3' | '4' | '5' | '6-30')[]) => {
    updateSelectionCriteria(['criteria', 'roomCount'], items);
  };
  const setHousingValue = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'housingValue'], items);
  };
  const setBrfOrgNo = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'brfOrgNo'], items);
  };
  const setAcreage = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'acreage'], items);
  };
  const setConstructionYear = (items: string[]) => {
    updateSelectionCriteria(['criteria', 'constructionYear'], items);
  };

  const [villaButtonCheck, setVillaButtonCheck] = useState<boolean>(
    housingType.includes('V') ||
      housingType.includes('R') ||
      housingType.includes('K'),
  );
  const [villaSubButtonCheck, setVillaSubButtonCheck] = useState<boolean>(
    housingType.includes('V') &&
      !(housingType.includes('R') && housingType.includes('K')),
  );
  const [radhusSubButtonCheck, setRadhusSubButtonCheck] = useState<boolean>(
    housingType.includes('R') &&
      !(housingType.includes('V') && housingType.includes('K')),
  );
  const [kedjehusSubButtonCheck, setKedjehusSubButtCheck] = useState<boolean>(
    housingType.includes('K') &&
      !(housingType.includes('R') && housingType.includes('V')),
  );
  const handleToggle = (
    value: 'V' | 'B' | 'H' | 'X' | 'F' | 'K' | 'R' | 'G',
  ) => {
    if (housingType.includes(value)) {
      // Remove housing Type, also remove depending critiera
      clearUpSubCriteria(value);
      if (
        housingType.length === 0 ||
        (housingType.length === 1 && housingType.includes(value))
      ) {
        setHousingArea([]);
      }
      setHousingType(housingType.filter((item) => item !== value));
    } else {
      setHousingType([...housingType, value]);
    }
  };

  const toggleVilla = () => {
    setVillaButtonCheck(!villaButtonCheck);
    const types: ('V' | 'B' | 'H' | 'X' | 'F' | 'K' | 'R' | 'G')[] = [
      'V',
      'K',
      'R',
    ];
    // If V K R, then button is active => deactivate it
    if (types.some((type) => housingType.includes(type))) {
      console.log('deactivate');
      clearUpSubCriteria('V');
      setHousingType(housingType.filter((type) => !types.includes(type)));
      // if
    } else {
      console.log('activate');
      setHousingType([...housingType, ...types]);
    }
  };

  const clearUpSubCriteria = (housingType: string) => {
    if (housingType === 'V') {
      setAcreage([]);
      setConstructionYear([]);
    } else if (housingType === 'B') {
      setBrfOrgNo([]);
      setRoomCount([]);
    } else if (housingType === 'H') {
      setRoomCount([]);
    }
  };

  const handleChange = (value: string, checked: boolean) => {
    const typedValue = value as '1' | '2' | '3' | '4' | '5' | '6-30';

    if (checked) {
      setRoomCount([...roomCount, typedValue]);
    } else {
      setRoomCount(roomCount.filter((item) => item !== value));
    }
  };

  // Below logic is to make the view mimic the old interface that worked slightly different
  // than what would have been the easiest implementation in this case.
  useEffect(() => {
    const allTypes: ('V' | 'B' | 'H' | 'X' | 'F' | 'K' | 'R' | 'G')[] = [
      'V',
      'R',
      'K',
    ]; // Define the types related to villa
    let updatedHousingType = housingType;

    if (!villaButtonCheck) {
      // Case 1: villaButtonCheck is false - remove all V, R, K
      updatedHousingType = updatedHousingType.filter(
        (type) => !allTypes.includes(type),
      );
    } else if (
      !villaSubButtonCheck &&
      !radhusSubButtonCheck &&
      !kedjehusSubButtonCheck
    ) {
      // Case 2: villaButtonCheck is true and all sub-buttons are false - add V, R, K
      updatedHousingType = Array.from(
        new Set(updatedHousingType.concat(allTypes)),
      ); // Convert Set to Array
    } else {
      // Case 3: villaButtonCheck is true, and sub-buttons reflect selection
      updatedHousingType = updatedHousingType.filter(
        (type) => !allTypes.includes(type),
      ); // Remove all V, R, K
      if (villaSubButtonCheck) updatedHousingType.push('V');
      if (radhusSubButtonCheck) updatedHousingType.push('R');
      if (kedjehusSubButtonCheck) updatedHousingType.push('K');
    }

    // Update the selection criteria with the final result
    setHousingType(updatedHousingType);
  }, [
    villaButtonCheck,
    villaSubButtonCheck,
    radhusSubButtonCheck,
    kedjehusSubButtonCheck,
  ]);

  return (
    <>
      {active && (
        <div className={`${styles.criterionArea} ${styles.CriteriaGroup}`}>
          <h3>
            <HouseIcon />
            &nbsp;Boendeform:
          </h3>
          <div className={`${styles.iconButtonMenu} ${styles.marginBottom}`}>
            <ToggleButton
              label="Villa"
              iconName="house" // Use an appropriate icon name
              isSelected={villaButtonCheck}
              onClick={() => toggleVilla()}
            />
            <ToggleButton
              label="Bostadsrätt"
              iconName="building" // Use an appropriate icon name
              isSelected={housingType.includes('B')}
              onClick={() => handleToggle('B')}
            />
            <ToggleButton
              label="Hyresrätt"
              iconName="hr" // Use an appropriate icon name
              isSelected={housingType.includes('H')}
              onClick={() => handleToggle('H')}
            />
            <ToggleButton
              label="Övrigt"
              iconName="iglo" // Use an appropriate icon name
              isSelected={housingType.includes('X')}
              onClick={() => handleToggle('X')}
            />
            {/*<label className={housingType.includes('X') ? styles.checked : ''}>
              <span>Fritidshus</span>
              <input
                type="checkbox"
                value="X"
                checked={housingType.includes('X')}
                onChange={handleChange}
              />
            </label> */}
          </div>
          {housingType.length > 0 && (
            <div className={styles.subBox}>
              <h3>Alternativ</h3>
              <span className={styles.criteriaLabel}>Välj boyta:</span>
              <NumberSpan
                numberSpan={housingArea ?? []}
                setNumberSpan={(numberSpan) =>
                  setHousingArea([...housingArea, numberSpan])
                }
                unit={Units.SQUARE_METERS}
                addMode={true}
              />
              <StringItemList
                items={housingArea}
                unit={Units.SQUARE_METERS}
                updateItems={setHousingArea}
              />
              {(housingType.includes('B') || housingType.includes('H')) && (
                <>
                  <div className={styles.criteriaLabel}>Välj antal rum:</div>
                  <div className={styles.menu}>
                    <CheckboxLabel
                      checked={roomCount.includes('1')}
                      value="1"
                      label="1:or"
                      setValue={handleChange}
                    />
                    <CheckboxLabel
                      checked={roomCount.includes('2')}
                      value="2"
                      label="2:or"
                      setValue={handleChange}
                    />
                    <CheckboxLabel
                      checked={roomCount.includes('3')}
                      value="3"
                      label="3:or"
                      setValue={handleChange}
                    />
                    <CheckboxLabel
                      checked={roomCount.includes('4')}
                      value="4"
                      label="4:or"
                      setValue={handleChange}
                    />
                    <CheckboxLabel
                      checked={roomCount.includes('5')}
                      value="5"
                      label="5:or"
                      setValue={handleChange}
                    />
                    <CheckboxLabel
                      checked={roomCount.includes('6-30')}
                      value="6-30"
                      label="6:or+"
                      setValue={handleChange}
                    />
                  </div>
                </>
              )}
              {/* HousingValue can not be included yet, no permission in SWS */}
              {false && (
                <HousingValue
                  housingValue={housingValue ?? []}
                  setHousingValue={setHousingValue}
                />
              )}
            </div>
          )}
          {housingType.includes('B') && (
            <div className={styles.subBox}>
              <h3>Alternativ för BRF</h3>
              <Brf brfOrgNo={brfOrgNo} setBrfOrgNo={setBrfOrgNo} />
            </div>
          )}
          {villaButtonCheck && (
            <div className={styles.subBox}>
              <h3>Alternativ för Villa</h3>
              <div className={`${styles.menu} ${styles.marginBottom}`}>
                <CheckboxLabel
                  checked={villaSubButtonCheck}
                  value="V"
                  label="Friliggande villa"
                  setValue={() => {
                    setVillaSubButtonCheck(!villaSubButtonCheck);
                  }}
                />{' '}
                <CheckboxLabel
                  checked={radhusSubButtonCheck}
                  value="R"
                  label="Radhus"
                  setValue={() => {
                    setRadhusSubButtonCheck(!radhusSubButtonCheck);
                  }}
                />{' '}
                <CheckboxLabel
                  checked={kedjehusSubButtonCheck}
                  value="K"
                  label="Kedjehus"
                  setValue={() => {
                    setKedjehusSubButtCheck(!kedjehusSubButtonCheck);
                  }}
                />
              </div>
              <div className={styles.criteriaLabel}>Välj byggår:</div>
              <YearMonthSpan
                setYearMonthSpan={(span) =>
                  setConstructionYear([...constructionYear, span])
                }
                onlyYear={true}
              />
              <StringItemList
                items={constructionYear}
                updateItems={setConstructionYear}
              />
              <Acreage acreage={acreage} setAcreage={setAcreage} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HousingCriteriaGroup;
