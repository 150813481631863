import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { getCount, updateCount } from '../../api';
import styles from './styles/Delivery.module.scss';
import { SelectionCount, Selection } from '../../models';

interface CountSettingProps {
  refNo: string;
  selection: Selection;
  updateSelectionCriteria: (path: string[], value: any) => void;
}

const CountSettings: React.FC<CountSettingProps> = ({ refNo, selection, updateSelectionCriteria }) => {

  const [count, setCount] = useState<Number>(selection.limit);
  const [loading, setLoading] = useState(true);

  // Fetch initial count when component mounts
  useEffect(() => {
    const fetchInitialCount = async () => {
      try {
        const initialCount = selection.limit;
        setCount(initialCount);
        setLoading(false); // Disable loading once we have the data
      } catch (error) {
        console.error('Failed to fetch initial count', error);
      }
    };

    fetchInitialCount();
  }, []); // Empty dependency array ensures this runs only once on mount

  // Handle input change and update count
  const handleChange = async (event: any) => {
    const newCount = event.target.value;
    console.log('Setting new count: ', newCount);

    try {
      //let result = await updateCount(newCount, refNo);
      updateSelectionCriteria(['limit'], newCount);
      console.log('Resulttt: ', newCount);
      setCount(newCount);
    } catch (error) {
      console.error('Failed to update count', error);
    }
  };

  return (
    <div className={`${styles.criterionArea}`}>
      <h3>Antal:</h3>
      <FormControl variant="standard">
        <TextField
          id="count"
          name="count"
          type="number"
          value={loading ? 'loading...' : count} // Display loading or the count
          onChange={handleChange}
          label="Count"
          InputLabelProps={{ shrink: true }}
          disabled={loading} // Disable input during loading
        />
      </FormControl>
    </div>
  );
};

export default CountSettings;
