import { Assignment, Selection, SelectionCount } from '../../models';
import { CountResponse } from './model';

const sendMessage = (msg: string): void => {
  if (window.parentIFrame) {
    window.parentIFrame.sendMessage(msg);
  } else {
    console.warn(
      'Trying to report to parent, but none available. Message was:',
      msg,
    );
  }
};

const sendHello = (): void => {
  console.log('Sending hello');
  sendMessage(JSON.stringify({ msg: 'Hello from Urval - rdy for action' }));
};

const sendCount = (count: SelectionCount): void => {
  sendMessage(JSON.stringify(count));
};

const reportCountAndSelection = (
  selection: Selection,
  assignment: Assignment,
  countResponse: SelectionCount,
) => {
  if (window.parentIFrame) {
    const result = getCountResponse(selection, assignment, countResponse);
    sendMessage(JSON.stringify(result));
  }
};

const getCountResponse = (
  selection: Selection,
  assignment: Assignment,
  countResponse: SelectionCount,
): CountResponse => {
  const criteria = selection.criteria || {};
  const refCopies = assignment.referenceCopies || [];
  type HousingType = 'K' | 'F' | 'V' | 'B' | 'H' | 'X' | 'R' | 'G';

  const transformRooms = (
    housingType: HousingType,
  ): { from: number; to: number }[] => {
    if (
      selection.criteria?.housingType?.includes(housingType) &&
      selection.criteria?.roomCount
    ) {
      const numericRoomCounts = selection.criteria.roomCount
        .map((room) => parseInt(room, 10))
        .filter((room) => !isNaN(room)); // Convert strings to numbers and filter invalid values

      if (numericRoomCounts.length > 0) {
        return [
          {
            from: Math.min(...numericRoomCounts),
            to: Math.max(...numericRoomCounts),
          },
        ];
      }
    }
    return [];
  };
  return {
    numWithAddress: countResponse.count,
    numWithPhone: null, // Placeholder, replace with actual data
    numRefexAddress:
      refCopies.length > 0
        ? refCopies.filter((copy) => !!copy.address).length
        : 0,
    addressSelection: assignment.addressSelection,
    phoneSelection: assignment.phoneSelection,
    villa: criteria.housingType?.includes('V') || false,
    ownedApartment: criteria.housingType?.includes('B') || false,
    rentedApartment: criteria.housingType?.includes('H') || false,
    unknown: criteria.housingType?.includes('X') || false,
    fritidshus: criteria.housingType?.includes('F') || false,
    farmHouse: criteria.housingType?.includes('G') || false,
    gender:
      criteria.gender?.[0] === 'M'
        ? 'MALE'
        : criteria.gender?.[0] === 'K'
          ? 'FEMALE'
          : 'NONE',
    referenceCopies: refCopies.map((copy) => ({
      firstName: copy.firstName,
      lastName: copy.lastName,
      careOf: copy.careOf,
      address: copy.address,
      zip: copy.zip,
      city: copy.city,
      phone: copy.phone,
      numberOf: copy.count,
    })),
    buildYears:
      criteria.constructionYear?.map((yearRange) => {
        const [from, to] = yearRange.split('-').map(Number);
        return { from: from || null, to: to || null };
      }) || [],
    geographyType: selection.proximity
      ? 'PROXIMITY'
      : selection.criteria?.geo
        ? 'MAP'
        : undefined,
    roomsOwnedApartment: transformRooms('B'),
    roomsRentedApartment: transformRooms('H'),
    addresses:
      selection.criteria?.streetAddress?.map((address) => {
        // Parse streetNumber into lower and upper bounds
        let streetNumber = address.streetNumber?.[0] || '';
        let streetNumberLowerBound: string | null = null;
        let streetNumberUpperBound: string | null = null;

        if (streetNumber.includes('-')) {
          const [lower, upper] = streetNumber
            .split('-')
            .map((num) => num.trim());
          streetNumberLowerBound = lower || null;
          streetNumberUpperBound = upper || null;
          streetNumber = ''; // Empty since we have bounds
        }

        return {
          streetName: address.streetName || null,
          streetNumber: streetNumber || null,
          streetNumberLowerBound: streetNumberLowerBound,
          streetNumberUpperBound: streetNumberUpperBound,
          zipCodeLowerBound: null, // Assuming this is not parsed separately
          zipCodeUpperBound: null, // Assuming this is not parsed separately
          city: address.city || null,
          municipality: null, // Add if you have municipality data
          oddEvenAll: 'ALL', // Default to ALL since no specific parity is given
        };
      }) || [],

    clusters: [], // Assuming no mapping yet, replace if needed
    brfNames: criteria.brfOrgNo || [],
    latLng: selection.proximity
      ? { lat: selection.proximity.lat, lng: selection.proximity.lon }
      : null,
    ages:
      criteria.age?.map((ageRange) => {
        const [from, to] = ageRange.split('-').map(Number);
        return { fromAge: from || null, toAge: to || null };
      }) || [],
    lastMove:
      criteria.moveDate?.map((dateRange) => {
        const [fromDate, toDate] = dateRange.split('-');
        return { fromDate: fromDate || null, toDate: toDate || null };
      }) || [],
    limited: selection.limit > 0,
    postLimit: null, // Placeholder, replace with actual data
    phoneLimit: null, // Placeholder, replace with actual data
  };
};

export const flexprint = {
  reportCountAndSelection,
  sendCount,
  sendHello,
};
