import { useLoadScript } from '@react-google-maps/api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { optimalAPI } from '../api/';
import { flexprint } from '../api/flexprint';
import { ActiveMenuItems, MenuItem, libraries } from '../conf/constants';
import googleConfig from '../conf/google.json';
import { GlobalContext } from '../GlobalContext';
import {
  Assignment,
  GeoCoordinates,
  Selection,
  SelectionCount,
  getDefaultSelection,
} from '../models';
import Geo from './criteria/Geo';
import HousholdCriteriaGroup from './criteria/HouseholdCriteriaGroup';
import HousingCriteriaGroup from './criteria/HousingCriteriaGroup';
import LocationCriteriaGroup from './criteria/LocationCriteriaGroup';
import MoveCriteriaGroup from './criteria/MoveCriteriaGroup';
import PersonCriteriaGroup from './criteria/PersonCriteriaGroup';
import CountSettings from './delivery_settings/CountSettings';
import DeliverySettings from './delivery_settings/DeliverySettings';
import ExclusionSettings from './delivery_settings/ExclusionSettings';
import RefExSettings from './delivery_settings/RefExSettings';
import UrvalAntal from './interface/UrvalAntal';
import UrvalsMeny from './interface/UrvalsMeny';
import ShowIcons from './misc/icons/ShowIcons';
import styles from './OptimalUrval.module.scss';

const OptimalUrval: React.FC = () => {
  const [selection, setSelection] = useState<Selection>({} as Selection);
  const [selectionCount, setSelectionCount] = useState<SelectionCount>(
    {} as SelectionCount,
  );
  const [activeMenuComponents, setActiveMenuComponents] =
    useState<ActiveMenuItems>({});
  const [selectionInit, setSelectionInit] = useState<boolean>(false);
  // TODO: Create a useeffect hook to and update backend when assignment data changes
  // TODO: Connect exclusions and refex to the same update procedure
  const [assignmentData, setAssignmentData] = useState<Assignment | null>(null);
  const context = useContext(GlobalContext);
  const [error, setError] = useState<string>('');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleConfig.apiKey,
    libraries,
  });

  if (!context) {
    setError('Global context not initiated.');
    throw new Error('Global context not initiated.');
  }

  const { globalObject, setGlobalObject } = context;

  const getRefNo = (): string => {
    const queryParams = new URLSearchParams(window.location.search);
    let refNo = queryParams.get('refNo');

    if (!refNo) {
      refNo = sessionStorage.getItem('refNo');
      if (!refNo) {
        setError('No reference number provided in the URL.');
        return '';
      }
    }

    return refNo;
  };

  useEffect(() => {
    const reportCountResult = () => {
      if (selectionCount.count && assignmentData) {
        // flexprint.sendCount(selectionCount);
        flexprint.reportCountAndSelection(
          selection,
          assignmentData,
          selectionCount,
        );
      }
    };
    reportCountResult();
  }, [selectionCount]);

  useEffect(() => {
    const fetchSelection = async () => {
      const refNo = getRefNo();
      if (!refNo) {
        setError('No reference number provided.');
        return;
      }
      setGlobalObject({ ...globalObject, refNo: refNo });
      setSelection(getDefaultSelection(refNo));
      try {
        const result = await optimalAPI.registerSession(refNo);
        setAssignmentData(result);
        const fetchedSelection = await optimalAPI.getSelection(refNo);
        console.log('Fetched selection: ', fetchedSelection);
        if (!fetchedSelection.criteria) {
          fetchedSelection.criteria = {};
        }
        setSelection(fetchedSelection);
        setSelectionInit(true);
        flexprint.sendHello();
      } catch (e: any) {
        setError(`Failed to fetch selection: ${e.message}`);
      }
    };
    console.log('Optimal Urval init.');
    fetchSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSelectionObject = useCallback(
    <T,>(path: string[], value: T): void => {
      console.log('Update selection object:', value, ' and ', path);
      setSelection((currentSelection) => {
        const updatePath = <T,>(obj: any, path: string[], value: T): any => {
          const [first, ...rest] = path;
          if (rest.length === 0) {
            obj[first] = value;
          } else {
            if (obj[first] === undefined) obj[first] = {};
            obj[first] = updatePath(obj[first], rest, value);
          }
          return obj;
        };
        return currentSelection
          ? updatePath({ ...currentSelection }, path, value)
          : currentSelection;
      });
    },
    [],
  );

  const updateAssignment = async (assignment: Assignment) => {
    try {
      if (globalObject && globalObject.refNo) {
        if (await optimalAPI.updateAssignment(globalObject.refNo, assignment)) {
          setAssignmentData(assignment);
        }
      } else {
        throw Error('No global object set');
      }
    } catch (error: any) {
      console.error(error || error.message);
    }
  };

  /** This function transfers a value from one path to another in the selection json
   * resulting in only one rerender and only one antalsberäkning-call.
   * (which the useEffect hook handles)
   */
  const transferValueInSelectionObject = useCallback(
    (sourcePath: string[], targetPath: string[], value: string): void => {
      console.log(`Move value: "${value}" from`, sourcePath, 'to', targetPath);
      setSelection((currentSelection) => {
        const updatePath = (
          obj: any,
          path: string[],
          updater: (arr: any[]) => any[],
        ): any => {
          const [first, ...rest] = path;
          const newObj = { ...obj }; // Clone the current object
          if (rest.length === 0) {
            newObj[first] = updater(obj[first] || []);
          } else {
            newObj[first] = updatePath(obj[first] || {}, rest, updater);
          }
          return newObj; // Return the updated clone
        };

        if (!currentSelection) return currentSelection;

        // Clone the selection object for immutability
        let updatedSelection = { ...currentSelection };

        // Remove the value from the source path
        updatedSelection = updatePath(updatedSelection, sourcePath, (arr) =>
          arr.filter((item: string) => item !== value),
        );

        // Add the value to the target path
        updatedSelection = updatePath(updatedSelection, targetPath, (arr) => [
          ...arr,
          value,
        ]);

        return updatedSelection;
      });
    },
    [],
  );

  const updateProximity = useCallback(
    (limit: number, proximity: GeoCoordinates | undefined) => {
      setSelection({
        ...selection,
        limit: limit,
        proximity: proximity,
      });
    },
    [selection],
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!selection) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {selectionInit && (
        <div className={styles.OptimalUrval}>
          <div className={styles.interfaceRow}>
            <h1>Specificera ditt urval</h1>
            <UrvalAntal
              selection={selection}
              selectionCount={selectionCount}
              setSelectionCount={setSelectionCount}
            />
          </div>
          {selection && selection.criteria && (
            <>
              <UrvalsMeny
                activeMenuComponents={activeMenuComponents}
                setActiveMenuComponents={setActiveMenuComponents}
                selection={selection}
              />
              <div className={styles.DinaUrval}>
                <h2>Dina urval:</h2>
                <HousingCriteriaGroup
                  active={activeMenuComponents[MenuItem.housing]}
                  housingType={selection.criteria.housingType ?? []}
                  roomCount={selection.criteria.roomCount ?? []}
                  housingArea={selection.criteria.housingArea ?? []}
                  housingValue={selection.criteria.housingValue ?? []}
                  brfOrgNo={selection.criteria.brfOrgNo ?? []}
                  acreage={selection.criteria.acreage ?? []}
                  constructionYear={selection.criteria.constructionYear ?? []}
                  updateSelectionCriteria={updateSelectionObject}
                />
                <PersonCriteriaGroup
                  active={activeMenuComponents[MenuItem.person]}
                  age={selection.criteria.age ?? []}
                  birthYearAndMonth={selection.criteria.birthYearAndMonth ?? []}
                  birthMonth={selection.criteria.birthMonth ?? []}
                  gender={selection.criteria.gender ?? []}
                  updateSelectionCriteria={updateSelectionObject}
                />
                <MoveCriteriaGroup
                  active={activeMenuComponents[MenuItem.move]}
                  moveDate={selection.criteria.moveDate ?? []}
                  excludingMoveDate={selection.criteria.moveDate ?? []}
                  countyBeforeLastMove={
                    selection.criteria.countyBeforeLastMove ?? []
                  }
                  excludingCountyBeforeLastMove={
                    selection.criteria.excludingCountyBeforeLastMove ?? []
                  }
                  municipalityBeforeLastMove={
                    selection.criteria.municipalityBeforeLastMove ?? []
                  }
                  excludingMunicipalityBeforeLastMove={
                    selection.criteria.excludingMunicipalityBeforeLastMove ?? []
                  }
                  cityBeforeLastMove={
                    selection.criteria.cityBeforeLastMove ?? []
                  }
                  excludingCityBeforeLastMove={
                    selection.criteria.excludingCityBeforeLastMove ?? []
                  }
                  updateSelectionCriteria={updateSelectionObject}
                  transferSelectionCriteria={transferValueInSelectionObject}
                />
                <LocationCriteriaGroup
                  googleLoaded={isLoaded}
                  active={activeMenuComponents[MenuItem.location]}
                  county={selection.criteria.county ?? []}
                  excludingCounty={selection.criteria.excludingCounty ?? []}
                  municipality={selection.criteria.municipality ?? []}
                  excludingMunicipality={
                    selection.criteria.excludingMunicipality ?? []
                  }
                  aRegion={selection.criteria.aRegion ?? []}
                  hRegion={selection.criteria.hRegion ?? []}
                  zip={selection.criteria.zip ?? []}
                  excludingZip={selection.criteria.excludingZip ?? []}
                  city={selection.criteria.city ?? []}
                  excludingCity={selection.criteria.excludingCity ?? []}
                  electoralDistrict={selection.criteria.electoralDistrict ?? []}
                  addressType={selection.criteria.addressType ?? []}
                  streetAddress={selection.criteria.streetAddress ?? []}
                  updateSelectionCriteria={updateSelectionObject}
                  transferSelectionCriteria={transferValueInSelectionObject}
                />
                <HousholdCriteriaGroup
                  active={activeMenuComponents[MenuItem.household]}
                  householdComposition={
                    selection.criteria.householdComposition ?? []
                  }
                  consumptionPower={selection.criteria.consumptionPower ?? []}
                  updateSelectionCriteria={updateSelectionObject}
                />
                <Geo
                  googleLoaded={isLoaded}
                  geo={selection.criteria.geo ?? []}
                  active={activeMenuComponents[MenuItem.geo]}
                  selectionCount={selectionCount}
                  setGeo={(items) =>
                    updateSelectionObject(['criteria', 'geo'], items)
                  }
                  updateProximity={updateProximity}
                  selection={selection}
                />
                {selection.criteria && (
                  <div className={styles.devInfo}>
                    <pre>{JSON.stringify(selection, null, 2)}</pre>
                    <pre>{JSON.stringify(assignmentData, null, 2)}</pre>
                  </div>
                )}
                {Object.keys(activeMenuComponents).every(
                  (key) => !activeMenuComponents[key],
                ) && (
                  <div style={{ padding: '5px' }}>Välj ett urval i menyn</div>
                )}
              </div>
              {globalObject && globalObject.refNo && assignmentData && (
                <div className={styles.Leverans}>
                  <h2>Leveransinställningar:</h2>
                  <DeliverySettings
                    assignment={assignmentData}
                    setAssignment={updateAssignment}
                  />
                  <RefExSettings refNo={globalObject.refNo} />

                  <CountSettings
                    refNo={globalObject.refNo}
                    selection={selection}
                    updateSelectionCriteria={updateSelectionObject}
                  />

                  <ExclusionSettings refNo={globalObject.refNo} />
                </div>
              )}
            </>
          )}
          <ShowIcons />
        </div>
      )}
    </>
  );
};

export default OptimalUrval;
